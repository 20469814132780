import React, { useEffect, useState } from 'react'

import { SignageFoodStyle } from './signageFoodStyle.js'
import { SignageBeerStyle } from './signageBeerStyle.js'

export const SignageStyle = ({
  medias,
  beerTaps,
  timeToShowTapping,
  availableList,
}) => {
  const [showTapping, setsShowTapping] = useState(false)

  const MINUTE_MS = 60000 // 1 minute

  useEffect(() => {
    const today = new Date()
    const hoursCounting = today.getHours()
    if (hoursCounting >= timeToShowTapping) setsShowTapping(true)
    else setsShowTapping(false)

    const interval = setInterval(() => {
      const today = new Date()
      const hoursCounting = today.getHours()
      // console.log('hoursCounting : ', hoursCounting)
      if (hoursCounting >= timeToShowTapping) setsShowTapping(true)
      else setsShowTapping(false)
    }, MINUTE_MS)

    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [timeToShowTapping])

  return (
    <>
      {showTapping ? (
        <SignageBeerStyle beerTaps={beerTaps} availableList={availableList} />
      ) : (
        <SignageFoodStyle medias={medias} />
      )}
    </>
  )
}
