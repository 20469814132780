import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { COLORS } from './utils/styles'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const textBGColor = '#000000b8'
const beerTypeFontColor = '#efbf2e'

const ScWrapper = styled.div`
  width: 720px;
  height: 1280px;
  overflow: hidden;

  transform: rotate(270deg);
  position: absolute;
  top: -280px;
  left: 280px;
`
const ScTappingWrapper = styled.div`
  padding: 1px 4px 3px 1px;
`
const ScGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6px;
  row-gap: 6px;
`
const ScBeerItem = styled.div`
  position: relative;
  height: 420px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 4px black;
  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-position: ${(props) =>
    props.imagePosition === false
      ? 'center'
      : props.imagePosition === true
      ? 'bottom'
      : 'top'};
`
const ScBrandWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10%;
  background-color: ${textBGColor};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
`
const ScIndex = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  background-color: ${COLORS.ORANGE};
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  line-height: 25px;
  color: black;
  margin: 0 3px;
  box-shadow: 2px 2px 5px black;
`
const ScBrandText = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-right: 45px;
`
const ScCountryImageWrapper = styled.div`
  position: absolute;
  right: 0;
  margin-right: 3px;
  width: 35px;
  box-shadow: 2px 2px 5px black;
`
const ScCountryImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`
const ScDescriptionWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 120px;
  background-color: ${textBGColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ScName = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 3px;
  text-align: center;
  text-transform: uppercase;
`
const ScDetail = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`
const ScType = styled.div`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${beerTypeFontColor};
`
const ScPriceWrapper = styled.div`
  position: absolute;
  bottom: 1px;
`
const ScPriceFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ScPriceItem = styled.div`
  width: 100px;
  text-align: center;
  background-color: ${(props) => (props.color ? props.color : COLORS.ORANGE)};
  border-radius: 4px;
  margin: 0 7px 5px;
  box-shadow: 2px 2px 5px black;
`
const ScMl = styled.div`
  font-size: 15px;
  line-height: 17px;
  font-weight: bold;
  color: black;
`
const ScPrice = styled.div`
  font-size: 33px;
  line-height: 34px;
  font-weight: bold;
  padding-left: 5px;
`
const ScBaht = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
`
const ScPercent = styled.div`
  background-color: ${textBGColor};
  font-size: 12px;
  font-weight: bold;
  color: white;
  position: absolute;
  right: 5px;
  bottom: 125px;
  padding: 3px;
  border-radius: 5px;
`
const ScSoldout = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000d9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: bold;
  color: red;
`
const ScMedia = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

export const SignageBeerStyle = ({ beerTaps, availableList, promotion }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplaySpeed: 11500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    pauseOnHover: false,
  }
  return (
    <ScWrapper>
      <SlickSlider {...settings}>
        <ScTappingWrapper>
          <ScGridWrapper>
            {beerTaps.map((beer, index) => {
              return (
                <ScBeerItem
                  key={`beer-item-${beer.name}`}
                  image={beer.image.fluid.src}
                  imagePosition={beer.imagePositionForSignage}
                >
                  <ScPercent>
                    ABV {parseFloat(beer.alcoholPercentage).toFixed(1)}%
                  </ScPercent>
                  {availableList[index] === false && (
                    <ScSoldout>Sold Out!</ScSoldout>
                  )}
                  <ScBrandWrapper>
                    <ScIndex>{index + 1}</ScIndex>
                    <ScBrandText>{beer.brand}</ScBrandText>
                    {beer?.country && (
                      <ScCountryImageWrapper>
                        <ScCountryImage
                          image={beer.country.gatsbyImageData}
                          alt={beer.country.title}
                        />
                      </ScCountryImageWrapper>
                    )}
                  </ScBrandWrapper>
                  <ScDescriptionWrapper>
                    <ScName>{beer.name}</ScName>
                    {beer?.detail && <ScDetail>{beer.detail}</ScDetail>}
                    <ScType>[ {beer.type} ]</ScType>
                    <ScPriceWrapper>
                      <ScPriceFlex>
                        {!!beer.price && (
                          <ScPriceItem>
                            <ScMl>330 ml.</ScMl>
                            <ScPrice>
                              {beer.price}
                              <ScBaht>฿</ScBaht>
                            </ScPrice>
                          </ScPriceItem>
                        )}

                        {!!beer.pricePint && (
                          <ScPriceItem color={COLORS.PINK}>
                            <ScMl>500 ml.</ScMl>
                            <ScPrice>
                              {beer.pricePint}
                              <ScBaht>฿</ScBaht>
                            </ScPrice>
                          </ScPriceItem>
                        )}

                        {!!beer.priceLitre && (
                          <ScPriceItem color={COLORS.BLUE}>
                            <ScMl>1,000 ml.</ScMl>
                            <ScPrice>
                              {beer.priceLitre}
                              <ScBaht>฿</ScBaht>
                            </ScPrice>
                          </ScPriceItem>
                        )}
                      </ScPriceFlex>
                    </ScPriceWrapper>
                  </ScDescriptionWrapper>
                </ScBeerItem>
              )
            })}
          </ScGridWrapper>
        </ScTappingWrapper>
        {promotion.map((media, index) => {
          return (
            <ScMedia
              key={`media${index}-${media.title}`}
              image={media.gatsbyImageData}
              alt={media.title}
            />
          )
        })}
      </SlickSlider>
    </ScWrapper>
  )
}
