import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const ScWrapper = styled.div`
  width: 720px;
  height: 1280px;
  overflow: hidden;

  transform: rotate(270deg);
  position: absolute;
  top: -280px;
  left: 280px;
`

const ScMedia = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

export const SignageFoodStyle = ({ medias }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplaySpeed: 9500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    pauseOnHover: false,
  }
  return (
    <ScWrapper>
      <SlickSlider {...settings}>
        {medias.map((media, index) => {
          return (
            <ScMedia
              key={`media${index}-${media.title}`}
              image={media.gatsbyImageData}
              alt={media.title}
            />
          )
        })}
      </SlickSlider>
    </ScWrapper>
  )
}
