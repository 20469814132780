import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import { SignageStyle } from '../components/signageStyle.js'

class Signage extends React.Component {
  render() {
    const medias = get(this, 'props.data.contentfulDigitalSignage.media')
    const timeToShowTapping = get(
      this,
      'props.data.contentfulDigitalSignage.timeToShowTapping'
    )

    let beerTaps = get(this, 'props.data.contentfulTappingList.list')
    const specialTaps = get(this, 'props.data.contentfulTappingList.specialTap')

    beerTaps = beerTaps.slice(0, 8)
    beerTaps = [...beerTaps, specialTaps]

    const availableList = [
      get(this, 'props.data.contentfulTappingList.tap1'),
      get(this, 'props.data.contentfulTappingList.tap2'),
      get(this, 'props.data.contentfulTappingList.tap3'),
      get(this, 'props.data.contentfulTappingList.tap4'),
      get(this, 'props.data.contentfulTappingList.tap5'),
      get(this, 'props.data.contentfulTappingList.tap6'),
      get(this, 'props.data.contentfulTappingList.tap7'),
      get(this, 'props.data.contentfulTappingList.tap8'),
      get(this, 'props.data.contentfulTappingList.heineken'),
    ]

    return (
      <SignageStyle
        medias={medias}
        beerTaps={beerTaps}
        timeToShowTapping={timeToShowTapping}
        availableList={availableList}
      />
    )
  }
}

export default Signage

export const pageQuery = graphql`
  query LP_SignageQuery {
    contentfulDigitalSignage(contentful_id: { eq: "1smdCOyQzJQ8Aho0WiRw81" }) {
      media {
        title
        gatsbyImageData(placeholder: BLURRED, quality: 100, cropFocus: TOP)
        fluid {
          src
        }
      }
      timeToShowTapping
    }
    contentfulTappingList(contentful_id: { eq: "4CvogBULpgyQuJ1KSkvDmg" }) {
      list {
        brand
        brandFontSize
        name
        detail
        type
        alcoholPercentage
        price
        pricePint
        specialPrice
        image {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100, cropFocus: TOP)
          fluid {
            src
          }
        }
        imagePositionForSignage
        country {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }

      specialTap {
        brand
        brandFontSize
        name
        detail
        type
        alcoholPercentage
        price
        pricePint
        priceLitre
        specialPrice
        image {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          fluid {
            src
          }
        }
        imagePositionForSignage
        country {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }

      tap1
      tap2
      tap3
      tap4
      tap5
      tap6
      tap7
      tap8
      heineken
    }
  }
`
